<template>
	<v-form v-if="form.presetData">
		<v-container>
			<v-row
				><v-col cols="12" md="4">
					<TextBox
						v-model="$v.form.editingItem.firstName.$model"
						label="First Name"
						:err-msg="requiredError('firstName', 'First name is required')"
						:required="true"
						:disabled="isUpdate"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<TextBox
						v-model="$v.form.editingItem.lastName.$model"
						label="Last Name"
						:err-msg="requiredError('lastName', 'Last name is required')"
						:required="true"
						:disabled="isUpdate"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<TextBox
						v-model="$v.form.editingItem.eMail.$model"
						label="E-Mail"
						:err-msg="requiredError('eMail', 'E-Mail adress is required')"
						:required="true"
						:disabled="isUpdate"
					/>
				</v-col>
				<v-col cols="12" :md="isUpdate ? 6 : 4">
					<ComboBox
						v-model="$v.form.editingItem.roleId.$model"
						label="Role"
						:items="form.presetData.roleList"
						item-text="name"
						item-value="id"
						:err-msg="requiredError('roleId', 'Role is required')"
						:required="true"
					/>
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { dynamicFormMixin } from '@headerlift/library';
import globalValidations from '@/mixins/globalValidations';
import validations from './_validations';

export default {
	name: 'PartnerUserForm',
	mixins: [validationMixin, dynamicFormMixin, globalValidations, validations],
	props: ['form'],
	data() {
		return {
			defaultCurrency: {
				name: 'Select',
				id: null,
			},
		};
	},
	computed: {},
	watch: {
		'form.presetData': {
			handler() {
				this.$v.$reset();
				if (this.form.presetData)
					this.form.editingItem = { ...(this.form.presetData.user || this.$cloneDeep(this.form.defaultItem)) };
			},
		},
	},
	methods: {},
};
</script>

<style lang="scss" scoped></style>
