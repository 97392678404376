// action types
export const SET_HEARTBEAT = 'setHeartbeat';

// mutation types
const state = {
	siteStatus: false,
	reportingLastRefreshTime: null,
};

const getters = {
	siteStatus: (state) => state.siteStatus,
	reportingLastRefreshTime: (state) => state.reportingLastRefreshTime,
};

const actions = {
	[SET_HEARTBEAT](context, payload) {
		context.commit(SET_HEARTBEAT, payload);
	},
};

const mutations = {
	[SET_HEARTBEAT](state, payload) {
		state.siteStatus = payload.siteStatus;
		state.reportingLastRefreshTime = payload.reportingLastRefreshTime || null;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
