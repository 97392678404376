import { ApiService, switchLogoByHost } from '@headerlift/library';

export const IMPORT_CSV = 'importCSV';

const state = {};

const getters = {
	siteLogo: (state, getters, rootState, rootGetters) => switchLogoByHost(rootGetters.isDark),
};

const actions = {
	[IMPORT_CSV](context, { url, data }) {
		return new Promise((resolve, reject) => {
			ApiService.post(url, data)
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					reject(err);
				});
		});
	},
};

const mutations = {};

export default {
	state,
	actions,
	mutations,
	getters,
	namespaced: true,
};
