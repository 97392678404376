// reporting
import ApplicationMessageForm from '@/views/application-message/management/Form.vue';
import PartnerUserForm from '@/views/account/Form.vue';

const dataFormDialogComponentMixin = {
	components: {
		ApplicationMessageForm,
		PartnerUserForm,
	},
};
export default dataFormDialogComponentMixin;
