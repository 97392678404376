import { ApiService } from '@headerlift/library';

import { applicationMessageDisplayType } from '@/constants/global';

export const SET_PRODUCT_TYPE = 'setProductType';
export const SHOW_INBOX_MESSAGE = 'showInboxMessage';
export const HIDE_INBOX_MESSAGE = 'hideInboxMessage';
export const FETCH_INBOX_MESSAGE = 'fetchInboxMessage';
export const FETCH_TOTAL_COUNT = 'fetchTotalCount';
export const FETCH_MESSAGE = 'fetchMessage';
export const MARK_AS_READ = 'markAsRead';

const endPoints = {
	getMessageList: 'applicationmessage/getmessagelist',
	insert: 'applicationmessage/insert',
	markAsRead: 'applicationmessage/markasread',
};

const getDefaultState = () => ({
	showInboxDrawer: false,
	productType: 0,
	inboxMessages: [],
	totalCount: 0,
	appMessages: [],
});

const state = getDefaultState();
const getters = {
	inboxDrawer: (state) => state.showInboxDrawer,
	productType: (state) => state.productType,
	appInboxMessages: (state) => state.inboxMessages,
	appInboxTotalCount: (state) => state.totalCount,
	appNotificationMessages: (state) =>
		state.appMessages.filter((x) => x.displayType === applicationMessageDisplayType.NOTIFICATION && !x.isRead),
	appPopupMessages: (state) =>
		state.appMessages.filter((x) => x.displayType === applicationMessageDisplayType.POPUP && !x.isRead),
	appConfirmationMessages: (state) =>
		state.appMessages.filter((x) => x.displayType === applicationMessageDisplayType.CONFIRMATION && !x.isRead),
	dashboardMessages: (state) =>
		state.appMessages.filter((x) => x.displayType === applicationMessageDisplayType.DASHBOARD && !x.isRead),
	inboxMessageCount: (state) => state.appMessages.filter((x) => !x.isRead).length,
};

const actions = {
	[SET_PRODUCT_TYPE](context, product) {
		return new Promise((resolve) => {
			context.commit(SET_PRODUCT_TYPE, product);
			resolve();
		});
	},
	[SHOW_INBOX_MESSAGE](context, payload) {
		return new Promise((resolve, reject) => {
			const requestPayload = { allInboxMessage: true, lastRowId: payload?.lastRowId };
			ApiService.post(endPoints.getMessageList, requestPayload)
				.then((response) => {
					setTimeout(() => {
						context.commit(FETCH_INBOX_MESSAGE, { payload, data: response.data, count: response.totalCount });
						context.commit(SHOW_INBOX_MESSAGE);
						resolve();
					}, 600);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	[HIDE_INBOX_MESSAGE](context) {
		context.commit(SHOW_INBOX_MESSAGE);
	},
	[FETCH_INBOX_MESSAGE](context, payload) {
		ApiService.post(endPoints.getMessageList, payload).then((response) => {
			context.commit(FETCH_INBOX_MESSAGE, response.data);
			context.commit(FETCH_TOTAL_COUNT, response.totalCount);
		});
	},
	[FETCH_MESSAGE](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.getMessageList, payload).then((response) => {
				context.commit(FETCH_MESSAGE, response.data);
				resolve();
			});
		});
	},
	[MARK_AS_READ](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.markAsRead, payload).then(() => {
				context.commit(MARK_AS_READ, payload);
				resolve();
			});
		});
	},
};

const mutations = {
	[SET_PRODUCT_TYPE](state, type) {
		state.productType = type;
	},
	[SHOW_INBOX_MESSAGE](state) {
		state.showInboxDrawer = !state.showInboxDrawer;
	},
	[FETCH_INBOX_MESSAGE](state, response) {
		if (!response.payload?.lastRowId) {
			state.inboxMessages = response.data || [];
			state.totalCount = response.count;
		} else {
			response.data.forEach((x) => {
				state.inboxMessages.push(x);
			});
		}
	},
	[FETCH_TOTAL_COUNT](state, count) {
		state.totalCount = count;
	},
	[FETCH_MESSAGE](state, messages) {
		state.appMessages = messages || [];
		if (state.showInboxDrawer) {
			messages?.forEach((x) => {
				const exists = state.inboxMessages.find((y) => y.id === x.id);
				if (!exists) state.inboxMessages.splice(0, 0, x);
			});
		}
	},
	[MARK_AS_READ](state, payload) {
		const message = state.appMessages.find((x) => x.id === payload.id);
		if (message != null) message.isRead = true;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
