/* eslint-disable global-require */
/* eslint-disable no-console */

import { IN_BROWSER, IS_PROD, APP_VERSION } from '@headerlift/library';

if (IN_BROWSER && IS_PROD && navigator.serviceWorker) {
	const unix = `?v=${APP_VERSION}`;
	const { register } = require('register-service-worker');

	register(`${process.env.BASE_URL}service-worker.js${unix}`, {
		// eslint-disable-next-line no-unused-vars
		ready(registration) {
			// this line was used for cache issue in google cloud bucket
			if (registration.active && !navigator.serviceWorker.controller) registration.active.postMessage('claimMe');
			console.log(
				'[sw:headerlift] App is being served from cache by a service worker.\n' +
					'For more details, visit https://goo.gl/AFskqB',
			);
		},
		registered(registration) {
			console.log('[sw:headerlift] Service worker has been registered.');
			// Routinely check for app updates by testing for a new service worker.
			setInterval(() => {
				registration.update();
			}, 1000 * 5); // minutely checks
		},
		cached() {
			console.log('[sw:headerlift] Content has been cached for offline use.');
		},
		updatefound() {
			console.log('[sw:headerlift] New content is downloading...');
		},
		updated(registration) {
			console.log('[sw:headerlift] New content is available; please refresh.');
			const event = new CustomEvent('swUpdated', { detail: registration });
			document.dispatchEvent(event);
		},
		offline() {
			console.log('[sw:headerlift] No internet connection found. App is running in offline mode.');
		},
		error(error) {
			console.error('[sw:headerlift] Error during service worker registration:', error);
		},
	});
}
