// import store from 'vuex';
import {
	AutoCompleteBox,
	BottomSheet,
	CheckBoxList,
	ComboBox,
	CustomButton,
	DatePicker,
	PagedList,
	SimpleTable,
	SnackBarMessage,
	SwitchBox,
	TextBox,
	VueApexCharts,
	DataGrid,
} from '@headerlift/library';

import DataFormDialog from '@/components/DataFormDialog.vue';

const GlobalComponents = {
	install(Vue) {
		Vue.component(AutoCompleteBox.name, AutoCompleteBox);
		Vue.component(BottomSheet.name, BottomSheet);
		Vue.component(CheckBoxList.name, CheckBoxList);
		Vue.component(ComboBox.name, ComboBox);
		Vue.component(CustomButton.name, CustomButton);
		Vue.component(DatePicker.name, DatePicker);
		Vue.component(PagedList.name, PagedList);
		Vue.component(SimpleTable.name, SimpleTable);
		Vue.component(SnackBarMessage.name, SnackBarMessage);
		Vue.component(SwitchBox.name, SwitchBox);
		Vue.component(TextBox.name, TextBox);
		Vue.component(DataGrid.name, DataGrid);
		Vue.component('apexchart', VueApexCharts);

		Vue.component(DataFormDialog.name, DataFormDialog);
	},
};

export default GlobalComponents;
