import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

import { reports } from '@headerlift/library';
import common from './modules/common.module';
import auth from './modules/auth.module';
import sideBar from './modules/sideBar.module';
import snackBar from './modules/snackBar.module';
import userVerification from './modules/userVerification.module';
import confirmation from './modules/confirmation.module';
import applicationMessage from './modules/applicationMessage.module';
import adReportStatus from './modules/adReportStatus.module';

import heartbeat from './modules/heartbeat.module';

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

const authPersistedStateConfigs = {
	key: 'user',
	paths: ['auth', 'sideBar'],
	storage: {
		getItem: (key) => {
			let storeData = '';
			try {
				storeData = ls.get(key);
				// eslint-disable-next-line no-empty
			} catch (ex) {}

			return storeData;
		},
		setItem: (key, value) => ls.set(key, value),
		removeItem: (key) => ls.remove(key),
	},
};
const savedFilters = {
	key: 'saved-filters',
	paths: ['reports.userFilter.data'],
};

export default new Vuex.Store({
	modules: {
		common,
		auth,
		sideBar,
		snackBar,
		heartbeat,
		userVerification,
		confirmation,
		applicationMessage,
		adReportStatus,
		reports,
	},
	plugins: [createPersistedState(authPersistedStateConfigs), createPersistedState(savedFilters)],
});
