import { groups, methods } from '@/constants/global';
import permissions from '@/constants/index';
import store from '@/store/index';
import { ApiService, checkPermission } from '@headerlift/library';
import Vue from 'vue';
import VueRouter from 'vue-router';

const myDomainPageGuard = (to, from, next) => {
	if (store.getters.currentUser.userPartnerId !== null) {
		next();
		return;
	}
	next('/401');
};

const checkAdReportStatusModule = () => {
	const hasAdReportStatusPermission = checkPermission(store, {
		serviceName: groups.AdReportStatus,
		methodName: methods.Get,
	});
	if (hasAdReportStatusPermission) {
		store?.dispatch('showAdReportStatus');
	}
};

Vue.use(VueRouter);

export const dynamicMenu = [
	{
		path: '/dashboard',
		name: 'dashboard',
		meta: {
			icon: 'fas fa-layer-group',
			title: 'Dashboard',
			autoRefreshAfterToUpgrade: true,
		},
		component: () => import('@/views/dashboard/Index.vue'),
		showOnMenu: true,
	},
	{
		path: '/application-message',
		name: 'applicationMessage',
		meta: {
			icon: 'fas fa-envelope',
			permission: permissions.apMessagePermissions,
			title: 'Application Message',
			autoRefreshAfterToUpgrade: true,
		},
		component: () => import('@/views/application-message/management/Index.vue'),
		showOnMenu: false,
		showOnQuickMenu: true,
	},
	{
		path: '/billing/payment-settings',
		name: 'paymentSettingsQuick',
		meta: {
			icon: 'fa fa-money-bill-alt',
			title: 'Payment Settings',
			permission: permissions.paymentSettingsPermissions,
			activePermission: () => !store.getters.isSuperAdmin,
		},
		component: () => import('@/views/billing/payment-settings/Index.vue'),
		showOnMenu: false,
		showOnQuickMenu: true,
	},
	{
		path: '/my-domains',
		name: 'myDomains',
		meta: {
			icon: 'fas fa-envelope',
			title: 'My Domains',
			permission: permissions.domainPermissions,
			activePermission: () => store.getters.currentUser.userPartnerId !== null,
			autoRefreshAfterToUpgrade: true,
		},
		component: () => import('@/views/quick-menu/my-domain/Index.vue'),
		beforeEnter: myDomainPageGuard,
		showOnMenu: false,
		showOnQuickMenu: true,
	},
	{
		path: '/revenue-share',
		name: 'revenueShare',
		meta: {
			icon: 'fa-money-bill-wave-alt',
			title: 'Revenue Share',
			permission: permissions.revenueSharePermissions,
		},
		component: () => import('@/views/quick-menu/revenue-share/Index.vue'),
		showOnMenu: false,
		showOnQuickMenu: true,
	},
	{
		path: '/revenue-prediction',
		name: 'revenuePrediction',
		meta: {
			icon: 'fa fa-hourglass-half',
			title: 'Revenue Prediction',
			permission: permissions.revenuePredictionPermissions,
		},
		showOnMenu: true,
		component: () => import('@/views/revenue-prediction/Index.vue'),
	},
	{
		path: '/summary',
		name: 'summary',
		meta: {
			icon: 'fa fa-receipt',
			title: 'Summary',
			useAppMessageByProduct: true,
			permission: permissions.summaryReportPermissions,
		},
		showOnMenu: true,
		component: () => import('@/views/summary/Index.vue'),
	},
	{
		path: '/report-compare',
		name: 'reportCompare',
		meta: {
			icon: 'fa fa-exchange-alt',
			title: 'Compare Report',
			useAppMessageByProduct: true,
			permission: permissions.compareReportPermissions,
		},
		showOnMenu: true,
		component: () => import('@/views/compare/Index.vue'),
	},
	{
		path: '/',
		name: 'gd',
		meta: {
			icon: 'fa fa-gamepad',
			title: 'GameDistribution',
		},
		showOnMenu: true,
		children: [
			{
				path: '/',
				name: 'gdPublisher',
				meta: {
					icon: 'fa fa-desktop',
					title: 'Publisher',
				},
				showOnMenu: true,
				children: [
					{
						path: '/gd/publisher/advertising',
						name: 'gdPubAds',
						meta: {
							icon: 'fa fa-caret-right',
							title: 'Advertising',
							useAppMessageByProduct: true,
							permission: permissions.gdPublisherAdsReportPermissions,
						},
						component: () => import('@/views/game-distribution/advertising/publisher/Index.vue'),
						showOnMenu: true,
					},
					{
						path: '/gd/publisher/in-game-payments',
						name: 'gdPubIng',
						meta: {
							icon: 'fa fa-caret-right',
							title: 'InGame Reports',
							useAppMessageByProduct: true,
							permission: permissions.gdPublisherInGameReportPermissions,
						},
						component: () => import('@/views/game-distribution/in-game/publisher/Index.vue'),
						showOnMenu: true,
					},
				],
			},
			{
				path: '/',
				name: 'gdDeveloper',
				meta: {
					icon: 'fa fa-desktop',
					title: 'Developer',
				},
				showOnMenu: true,
				children: [
					{
						path: '/gd/developer/advertising',
						name: 'gdDevAds',
						meta: {
							icon: 'fa fa-caret-right',
							title: 'Advertising',
							useAppMessageByProduct: true,
							permission: permissions.gdDeveloperAdsReportPermissions,
						},
						component: () => import('@/views/game-distribution/advertising/developer/Index.vue'),
						showOnMenu: true,
					},
					{
						path: '/gd/developer/in-game-payments',
						name: 'gdDevIng',
						meta: {
							icon: 'fa fa-caret-right',
							title: 'InGame Reports',
							useAppMessageByProduct: true,
							permission: permissions.gdDeveloperInGameReportPermissions,
						},
						component: () => import('@/views/game-distribution/in-game/developer/Index.vue'),
						showOnMenu: true,
					},
				],
			},
		],
	},
	{
		path: '/',
		name: 'advertising',
		showOnMenu: true,
		meta: {
			icon: 'fab fa-buysellads',
			title: 'Advertising',
		},
		children: [
			{
				path: '/advertising/video',
				name: 'advertisingVideo',
				meta: {
					icon: 'fa fa-caret-right',
					title: 'Video',
					useAppMessageByProduct: true,
					permission: permissions.adsVideoReportPermissions,
				},
				component: () => import('@/views/advertising/video/Index.vue'),
				showOnMenu: true,
			},
			{
				path: '/advertising/display',
				name: 'advertisingDisplay',
				meta: {
					icon: 'fa fa-caret-right',
					title: 'Display',
					useAppMessageByProduct: true,
					permission: permissions.adsDisplayReportPermissions,
				},
				component: () => import('@/views/advertising/display/Index.vue'),
				showOnMenu: true,
			},
			{
				path: '/advertising/mobile',
				name: 'advertisingMobile',
				meta: {
					icon: 'fa fa-caret-right',
					title: 'Mobile',
					useAppMessageByProduct: true,
					permission: permissions.adsMobileReportPermissions,
				},
				component: () => import('@/views/advertising/mobile/Index.vue'),
				showOnMenu: true,
			},
		],
	},
	{
		path: '/premium-campaign',
		name: 'premiumCampaign',
		meta: {
			icon: 'fa-bullhorn',
			title: 'Premium Campaign',
			useAppMessageByProduct: true,
			permission: permissions.premiumCampaignPermissions,
		},
		component: () => import('@/views/premium-campaign/Index.vue'),
		showOnMenu: true,
	},
	{
		path: '/alarm-service',
		name: 'alarmService',
		meta: {
			icon: 'fa-bell',
			title: 'Alarm Service',
			useAppMessageByProduct: true,
			permission: permissions.alarmServicePermissions,
		},
		component: () => import('@/views/alarm-service/Index.vue'),
		showOnMenu: true,
	},
	{
		path: '/accounts',
		name: 'accounts',
		meta: {
			icon: 'fa fa-users',
			title: 'Accounts',
			permission: permissions.accountPermissions,
		},
		component: () => import('@/views/account/Index.vue'),
		showOnMenu: true,
	},
	{
		path: '/',
		name: 'billing',
		meta: {
			icon: 'fa fa-money-bill',
			title: 'Billing',
			activePermission: () => !store.getters.isSuperAdmin,
		},
		showOnMenu: true,
		children: [
			{
				path: '/billing/payments',
				name: 'payments',
				meta: {
					icon: 'fa fa-list',
					title: 'Payments',
					permission: permissions.paymentsPermissions,
				},
				component: () => import('@/views/billing/payment/Index.vue'),
				showOnMenu: true,
			},
			{
				path: '/billing/payment-settings',
				name: 'paymentSettings',
				meta: {
					icon: 'fa fa-money-bill-alt',
					title: 'Payment Settings',
					permission: permissions.paymentSettingsPermissions,
				},
				component: () => import('@/views/billing/payment-settings/Index.vue'),
				showOnMenu: true,
			},
		],
	},

	{
		path: '/ads-txt-management',
		name: 'adsTxtManagement',
		meta: {
			icon: 'fa fa-file',
			title: 'Ads.Txt Management',
			permission: permissions.adsTxtManagementPermissions,
			activePermission: () => store.getters.showFinancialValues && !store.getters.isSuperAdmin,
		},
		component: () => import('@/views/ads-txt-management/Index.vue'),
		showOnMenu: true,
	},
];

const flatten = (arr) => arr.reduce((acc, cur) => acc.concat(cur.children ? flatten(cur.children) : cur), []);

const permissionCheck = (route) => {
	if (route.meta.activePermission && typeof route.meta.activePermission === 'function') {
		return route.meta.activePermission();
	}
	if (!route.meta.permission) return true;
	return checkPermission(store, {
		serviceName: route.meta.permission.service,
		methodName: route.meta.permission.view,
	});
};

export const renderQuickMenu = () => dynamicMenu.filter((x) => x.showOnQuickMenu && permissionCheck(x));

export const renderDynamicMenu = () =>
	dynamicMenu
		.filter((x) => permissionCheck(x))
		.map((x) => ({
			...x,
			children: x.children?.filter((ch) => {
				const subMenu = ch;

				if (subMenu.meta.permission || subMenu.meta.activePermission) {
					return permissionCheck(subMenu);
				}

				if (ch.children) {
					subMenu.children = subMenu.children.filter((sch) => permissionCheck(sch));
					return subMenu;
				}
				return permissionCheck(subMenu);
			}),
		}))
		.filter((x) => x.showOnMenu && (x.children?.length > 0 || !x.children));

const routes = [
	{
		path: '/',
		redirect: '/dashboard',
		component: () => import('@/views/_shared/Base.vue'),
		children: flatten(dynamicMenu),
	},
	{
		path: '*',
		redirect: '/404',
	},
	{
		path: '/404',
		name: '404',
		component: () => import('@/views/_shared/error/404.vue'),
	},
	{
		path: '/401',
		name: '401',
		component: () => import('@/views/_shared/error/401.vue'),
	},
	{
		path: '/403',
		name: '403',
		component: () => import('@/views/_shared/error/403.vue'),
	},
	{
		path: '/503',
		name: '503',
		component: () => import('@/views/_shared/error/503.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach(async (to, from, next) => {
	while (!Vue.$keycloak) {
		await Vue.prototype.$sleep(100);
	}

	if (Vue.$keycloak.authenticated) {
		if (!store.getters.isAuthenticated) {
			const { token } = Vue.$keycloak;
			await store.dispatch('azerionSSO', { token });
		}

		const hasPermission =
			typeof to.meta.permission !== 'undefined'
				? checkPermission(store, { serviceName: to.meta.permission.service, methodName: to.meta.permission.view })
				: true;

		if (!hasPermission && store.getters.isAuthenticated) {
			next('/401');
			return;
		}

		setTimeout(() => {
			window.scrollTo(0, 0);
			next();
		}, 100);
	} else {
		let httpCheckCounter = 0;
		while (!ApiService.http) {
			await Vue.prototype.$sleep(100);
			httpCheckCounter += 1;
			if (httpCheckCounter === 50) break;
		}

		store.dispatch('logout').finally(() => {
			const loginUrl = Vue.$keycloak.createLoginUrl();
			window.location.replace(loginUrl);
		});
	}
});

router.afterEach((to) => {
	Vue.nextTick(() => {
		const title = to.meta.title ? ` | ${to.meta.title}` : '';
		document.title = `HL Reporting ${title}` || `HL Reporting`;
	});

	checkAdReportStatusModule();
});

export default router;
