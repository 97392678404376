<template>
	<v-form v-if="form.presetData">
		<v-container>
			<v-row>
				<v-col cols="12" md="4">
					<ComboBox
						v-model="$v.form.editingItem.messageType.$model"
						label="Message Type"
						item-text="value"
						item-value="id"
						:items="messageTypes"
						:err-msg="requiredError('messageType', 'Please select message type!')"
						:required="true"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<ComboBox
						v-model="$v.form.editingItem.displayType.$model"
						label="Display Type"
						item-text="value"
						item-value="id"
						:items="messageDisplayTypes"
						:err-msg="requiredError('displayType', 'Please select display type!')"
						:required="true"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<ComboBox
						label="Products"
						v-model="form.editingItem.products"
						item-text="value"
						item-value="id"
						:items="productTypes"
						:selected-count="2"
						:multiple="true"
						:clearable="true"
						:delete-chip="true"
						:attach="true"
						:chips="true"
						:clear-value="null"
					/>
				</v-col>
				<v-col cols="12" md="4">
					<DatePicker v-model="form.editingItem.startDate" label="Start Date" :clearable="true" />
				</v-col>
				<v-col cols="12" md="4">
					<DatePicker v-model="form.editingItem.endDate" label="End Date" :clearable="true" />
				</v-col>
				<v-col cols="12" md="4">
					<ComboBox
						v-model="form.editingItem.targetType"
						label="Target Type"
						item-text="value"
						item-value="id"
						:items="messageTargetTypes"
					/>
				</v-col>
				<v-col v-if="isExternalSpecific">
					<AutoCompleteBox
						label="Partners"
						v-model="$v.form.editingItem.partners.$model"
						placeholder="Search Partner"
						:clear-value="[]"
						:selected-count="2"
						:multiple="true"
						:item-text="'value'"
						:item-value="'id'"
						:search-end-point="form.endPoints.getPartnersByKeyword"
						:edit-end-point="form.endPoints.getPartnersById"
						:err-msg="partnerIndexErrors()"
						:required="true"
					/>
				</v-col>
				<v-col cols="12" md="12">
					<TextBox
						v-model="form.editingItem.title"
						label="Title"
						:placeholder="'This will be generated automatically'"
					/>
				</v-col>
				<v-col md="12">
					<ckeditor v-model="form.editingItem.text" :editor="editor" />
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</template>

<script>
import { ClassicEditor, dynamicFormMixin } from '@headerlift/library';
import { validationMixin } from 'vuelidate';
import { applicationMessageTargetType } from '@/constants/global';
import globalValidations from '@/mixins/globalValidations';
import validations from './_validations';

export default {
	name: 'ApplicationMessageForm',
	mixins: [validationMixin, dynamicFormMixin, globalValidations, validations],
	props: ['form'],
	data() {
		return {
			editor: ClassicEditor,
		};
	},
	computed: {
		computedOverflow() {
			return this.overFlow;
		},
		messageTypes() {
			return this.form.presetData.messageTypeList;
		},
		messageDisplayTypes() {
			return this.form.presetData.messageDisplayTypeList;
		},
		messageTargetTypes() {
			return this.form.presetData.messageTargetTypeList;
		},
		productTypes() {
			return this.form.presetData.productTypeList;
		},
		isExternalSpecific() {
			return this.form.editingItem.targetType === applicationMessageTargetType.EXTERNAL_SPECIFIC;
		},
	},
	watch: {
		'form.presetData': {
			handler() {
				if (this.form.presetData)
					this.form.editingItem = {
						...(this.form.presetData.applicationMessage || this.$cloneDeep(this.form.defaultItem)),
					};
			},
		},
	},
	methods: {},
};
</script>

<style lang="scss" scoped>
:deep() {
	.ck-editor {
		color: #333;
	}
	.ck-editor__editable {
		min-height: 250px;
	}
	.v-card {
		.v-card__text {
			overflow-x: hidden !important;
		}
	}
}
</style>
