/* eslint-disable no-unused-expressions */
import { required, email } from 'vuelidate/lib/validators';

// const passwordCheck = helpers.regex('passwordCheck', /^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!*@#$%^&+=]).*$/);

const userValidationMixin = {
	validations: {
		form: {
			editingItem: {
				firstName: {
					required,
				},
				lastName: {
					required,
				},
				eMail: {
					required,
					email,
				},
				roleId: {
					required,
				},
			},
		},
	},
	methods: {
		// validation methods for this page
	},
};

export default userValidationMixin;
