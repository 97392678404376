/* eslint-disable no-unused-expressions */

const globalValidations = {
	computed: {
		item() {
			return this.$v.form.editingItem;
		},
	},
	methods: {
		requiredError(col, msg) {
			const errors = [];
			const { $dirty, required } = typeof col === 'object' ? col : this.item[col];
			if (!$dirty) return errors;
			!required && errors.push(msg);
			return errors;
		},
		minValueError(col, msg) {
			const errors = [];
			const { $dirty, minValue } = typeof col === 'object' ? col : this.item[col];
			if (!$dirty) return errors;
			!minValue && errors.push(msg);
			return errors;
		},
		minLengthError(col, msg) {
			const errors = [];
			const { $dirty, minLength } = typeof col === 'object' ? col : this.item[col];
			if (!$dirty) return errors;
			!minLength && errors.push(msg);
			return errors;
		},
		autoCompleteBoxError(col, msg) {
			const errors = [];
			const { $dirty, required, minLength } = typeof col === 'object' ? col : this.item[col];
			if (!$dirty) return errors;
			!required && !minLength && errors.push(msg);
			return errors;
		},
		decimalError(col, msg) {
			const errors = [];
			const { $dirty, decimal, required } = typeof col === 'object' ? col : this.item[col];
			if (!$dirty) return errors;
			(!required || !decimal) && errors.push(msg);
			return errors;
		},
	},
};

export default globalValidations;
