import { ApiService } from '@headerlift/library';

export const SHOW_ADREPORT_STATUS = 'showAdReportStatus';

const endPoints = {
	getAdReportStatus: 'adreportstatus/get',
};

const getDefaultState = () => ({
	adReportStatusList: [],
	adReportStatusErrorCount: 0,
});

const state = getDefaultState();
const getters = {
	adReportStatus: (state) => state.adReportStatusList,
	adReportStatusErrorCount: (state) => state.adReportStatusList.filter((x) => x.status === 0).length,
};

const actions = {
	[SHOW_ADREPORT_STATUS](context, payload) {
		return new Promise((resolve) => {
			ApiService.post(endPoints.getAdReportStatus, payload).then((response) => {
				context.commit(SHOW_ADREPORT_STATUS, response);
				resolve();
			});
		});
	},
};
const mutations = {
	[SHOW_ADREPORT_STATUS](state, adReportStatusItems) {
		state.adReportStatusList = adReportStatusItems || [];
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
