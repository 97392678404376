// action types
export const OPEN_CONFIRMATION_MODAL = 'openConfirmationModal';
export const HIDE_CONFIRMATION_MODAL = 'hideConfirmationModal';
export const ACCEPT = 'accept';

const state = {
	confirmationModal: false,
	onSuccess: null,
	errorMessage: '',
};

const getters = {
	confirmationModal: (state) => state.confirmationModal,
	errorMessage: (state) => state.errorMessage,
};

const actions = {
	[OPEN_CONFIRMATION_MODAL](context, payload) {
		context.commit(OPEN_CONFIRMATION_MODAL, payload);
	},
	async [ACCEPT](context, payload) {
		const success = await context.state.onSuccess(payload);
		if (success) {
			context.commit(ACCEPT, payload);
		}
	},
	[HIDE_CONFIRMATION_MODAL](context) {
		context.commit(HIDE_CONFIRMATION_MODAL);
	},
};

const mutations = {
	[OPEN_CONFIRMATION_MODAL](state, { success, message }) {
		state.onSuccess = success;
		state.confirmationModal = true;
		state.errorMessage = message;
	},
	[ACCEPT](state) {
		state.confirmationModal = false;
		state.onSuccess = false;
		state.errorMessage = '';
	},
	[HIDE_CONFIRMATION_MODAL](state) {
		state.onSuccess = false;
		state.errorMessage = '';
		state.confirmationModal = false;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
