<template>
	<v-app>
		<v-snackbar v-model="updateExists" :timeout="-1">
			New version available! Click to update
			<template #action="{ attrs }">
				<v-btn color="green" text v-bind="attrs" @click="refreshApp"> Update </v-btn>
			</template>
		</v-snackbar>
		<nprogress-container />
		<SnackBarMessage />
		<router-view />
	</v-app>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer.vue';
import { HeartbeatService, SystemHelper } from '@headerlift/library';
import { SET_HEARTBEAT } from '@/store/modules/heartbeat.module';

export default {
	name: 'App',
	components: { NprogressContainer },
	data() {
		return {
			autoRefreshAfterToUpgrade: false,
			refreshing: false,
			registration: null,
			updateExists: false,
			snackbar: false,
			loaded: false,
		};
	},
	computed: {
		...mapGetters(['siteStatus']),
		isCheckSystemInfo() {
			return typeof this.$route.query['system-info'] !== 'undefined';
		},
	},
	beforeCreate() {
		const redirect = async (response) => {
			this.setHeartbeat(response).then(() => {
				this.heartbeatRedirect();
				this.loaded = true;
			});
		};
		HeartbeatService.start(redirect);
	},
	created() {
		document.addEventListener('swUpdated', this.processRefreshUI, { once: true });
		if (navigator.serviceWorker) {
			navigator.serviceWorker.addEventListener('controllerchange', () => {
				if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			});
		}
	},
	beforeDestroy() {
		document.removeEventListener('swUpdated', this.processRefreshUI);
	},
	methods: {
		...mapActions({
			setHeartbeat: SET_HEARTBEAT,
		}),
		processRefreshUI(e) {
			this.registration = e.detail;

			if (this.autoRefreshAfterToUpgrade) {
				this.refreshApp();
				return;
			}

			this.showRefreshUI();
		},
		showRefreshUI() {
			this.updateExists = true;
		},
		refreshApp() {
			this.updateExists = false;
			if (!this.registration || !this.registration.waiting) return;
			this.registration.waiting.postMessage('skipWaiting');
		},
		heartbeatRedirect() {
			const { currentRoute } = this.$router;

			if (this.siteStatus === 'offline' && currentRoute.name !== '403') {
				this.$router.push({ name: '403' });
			} else if (this.siteStatus === 'online' && currentRoute.name === '403') {
				this.$router.push({ name: 'dashboard' });
			}
		},
	},
	watch: {
		$route: {
			handler(value) {
				this.autoRefreshAfterToUpgrade = value.meta.autoRefreshAfterToUpgrade ?? false;
				// redirect page
				if (this.autoRefreshAfterToUpgrade) this.refreshApp();

				this.$nextTick(() => {
					if (this.isCheckSystemInfo) {
						SystemHelper.versionCheck();
					}
				});
			},
		},
		siteStatus: {
			handler() {
				if (this.loaded) this.heartbeatRedirect();
			},
			immediate: false,
		},
	},
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap');

html,
input,
select,
.v-label,
.v-input__control {
	font-size: 14px;
}
#app {
	font-family: 'Quicksand', sans-serif;
}

#nprogress .bar {
	background: rgb(255, 62, 87) !important;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background: #9e9e9e;
}

::-webkit-scrollbar-thumb {
	background: #6d6d6d;
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(58, 58, 58);
}

.theme--light {
	::-webkit-scrollbar-track {
		background: #e6e6e6;
	}

	::-webkit-scrollbar-thumb {
		background: #b0b0b0;
		border-radius: 7px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgb(104, 104, 104);
	}
}
.theme--dark {
	::-webkit-scrollbar-track {
		background: #3a3a3a;
	}

	::-webkit-scrollbar-thumb {
		background: #555555;
		border-radius: 7px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: rgb(78, 78, 78);
	}
}
</style>
