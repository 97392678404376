import { permission as apMessagePermissions } from '@/views/application-message/management/_constants';

// prebid-server
import { permission as compareReportPermissions } from '@/views/compare/_constants';
import { permission as revenuePredictionPermissions } from '@/views/revenue-prediction/_constants';
import { permission as summaryReportPermissions } from '@/views/summary/_constants';

// gd
import { permission as gdDeveloperAdsReportPermissions } from '@/views/game-distribution/advertising/developer/_constants';
import { permission as gdPublisherAdsReportPermissions } from '@/views/game-distribution/advertising/publisher/_constants';
import { permission as gdDeveloperInGameReportPermissions } from '@/views/game-distribution/in-game/developer/_constants';
import { permission as gdPublisherInGameReportPermissions } from '@/views/game-distribution/in-game/publisher/_constants';

// ads
import { permission as adsDisplayReportPermissions } from '@/views/advertising/display/_constants';
import { permission as adsMobileReportPermissions } from '@/views/advertising/mobile/_constants';
import { permission as adsVideoReportPermissions } from '@/views/advertising/video/_constants';

import { permission as accountPermissions } from '@/views/account/_constants';
import { permission as adsTxtManagementPermissions } from '@/views/ads-txt-management/_constants';
import { permission as alarmServicePermissions } from '@/views/alarm-service/_constants';
import { permission as paymentSettingsPermissions } from '@/views/billing/payment-settings/_constants';
import { permission as paymentsPermissions } from '@/views/billing/payment/_constants';
import { permission as premiumCampaignPermissions } from '@/views/premium-campaign/_constants';
import { permission as domainPermissions } from '@/views/quick-menu/my-domain/_constants';
import { permission as revenueSharePermissions } from '@/views/quick-menu/revenue-share/_constants';

export default {
	apMessagePermissions,

	// prebid-server
	revenuePredictionPermissions,
	summaryReportPermissions,
	compareReportPermissions,

	// gd
	gdPublisherAdsReportPermissions,
	gdPublisherInGameReportPermissions,
	gdDeveloperAdsReportPermissions,
	gdDeveloperInGameReportPermissions,

	// ads
	adsVideoReportPermissions,
	adsDisplayReportPermissions,
	adsMobileReportPermissions,

	accountPermissions,
	paymentsPermissions,
	paymentSettingsPermissions,
	adsTxtManagementPermissions,
	premiumCampaignPermissions,
	alarmServicePermissions,

	// quick menu
	domainPermissions,
	revenueSharePermissions,
};
