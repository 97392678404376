/* eslint-disable no-param-reassign */
import CloneDeep from 'lodash.clonedeep';
import { toTitleCase, sleep, sleepFn } from '@headerlift/library';

const GlobalFunctions = {
	install(Vue) {
		Vue.prototype.$cloneDeep = (obj) => CloneDeep(obj);
		Vue.prototype.$sleep = sleep;
		Vue.prototype.$sleepFn = (fn, ms) => sleepFn(fn, ms);
		Vue.prototype.$toTitleCase = (phrase) => toTitleCase(phrase);
	},
};

export default GlobalFunctions;
