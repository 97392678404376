import Vue from 'vue';
import Vuetify from 'vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import 'vuetify/dist/vuetify.min.css';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

const opts = {
	icons: {
		iconfont: 'mdiSvg',
	},
	theme: {
		themes: {
			dark: {
			},
		},
		options: {
			minifyTheme,
			customProperties: true,
		},
	},
};

export default new Vuetify(opts);
