import { ApiService, EventBus } from '@headerlift/library';
import Vue from 'vue';

import packageInfo from '../../../package.json';

// action types
export const VIEW_AS_LOGIN = 'viewAsLogin';
export const GET_PERMISSION = 'getPermission';
export const CHECK_PERMISSION = 'checkPermission';
export const LOGOUT = 'logout';
export const LOGOUT_VIEW_AS = 'logoutViewAs';
export const AZERION_SSO = 'azerionSSO';

// mutation types
export const PURGE_AUTH = 'logOut';
export const PURGE_VIEW_AS_AUTH = 'logOutViewAs';
export const SET_AUTH = 'setUser';
export const SET_TOKEN = 'setToken';
export const SET_VIEW_AS_AUTH = 'setViewAsUser';
export const SET_PERMISSION = 'setPermission';
export const SET_VIEW_AS_PERMISSION = 'setViewAsPermission';
export const SET_ERROR = 'setError';
export const PERMISSION_CHECKED = 'permissionChecked';

const state = {
	errors: null,
	user: null,
	viewAsUser: null,
	token: null,
	viewAsUsertoken: null,
	permissions: [],
	viewAsUserPermissions: [],
	viewAs: false,
	userPemissionChecked: false,
};

const getters = {
	isAuthenticated: (state) => !!state.user,
	currentUser: (state) => (state.viewAs ? state.viewAsUser : state.user),
	token: (state) => (state.viewAs ? state.viewAsUsertoken : state.token),
	permissions: (state) => (state.viewAs ? state.viewAsUserPermissions : state.permissions),
	isSuperAdmin: (state) =>
		state.viewAs ? state.viewAsUser.systemRule.isSuperAdmin : state.user.systemRule.isSuperAdmin,
	isSalesOffice: (state) => (state.viewAs ? state.viewAsUser.isSalesOfficePartner : state.user.isSalesOfficePartner),
	isPartner: (state) =>
		state.viewAs
			? !state.viewAsUser.systemRule.isSuperAdmin && !state.viewAsUser.isSalesOfficePartner
			: !state.user.systemRule.isSuperAdmin && !state.user.isSalesOfficePartner,
	isViewUser: (state) => state.viewAs,
	showFinancialValues: (state, getters) =>
		!getters.permissions.some((x) => x.method.toLowerCase() === 'nonfinancialvalues'),
	appVersion: () => packageInfo.version,
	isUserPermissionChecked: (state) => state.userPemissionChecked,
};

const actions = {
	[GET_PERMISSION](context, payload) {
		context.commit(PERMISSION_CHECKED, false);
		return new Promise((resolve, reject) => {
			ApiService.post('role/getuirights', payload)
				.then((data) => {
					if (!data) return;
					if (payload.viewAs) context.commit(SET_VIEW_AS_PERMISSION, data);
					else context.commit(SET_PERMISSION, data);

					context.commit(PERMISSION_CHECKED, true);
					resolve(data);
				})
				.catch((response) => {
					// invalid token
					context.commit(PERMISSION_CHECKED, false);
					if (response.responseCode !== 6003) context.commit(SET_ERROR, response.responseText);
					reject();
				});
		});
	},
	[CHECK_PERMISSION](context, payload) {
		return new Promise((resolve) => {
			if (payload.serviceName && context.getters.permissions) {
				const service = payload.serviceName.toLowerCase();
				const method = payload.methodName.toLowerCase();
				const hasPermission = context.getters.permissions.some(
					(x) => x.service.toLowerCase() === service && x.name.toLowerCase() === method,
				);
				resolve(hasPermission);
				return;
			}
			resolve(false);
		});
	},
	[SET_TOKEN](context, token) {
		context.commit(SET_TOKEN, token);
	},
	async [AZERION_SSO](context, { token }) {
		await context.dispatch(SET_TOKEN, token);
		return new Promise((resolve) => {
			const ui = 'reporting';
			ApiService.post(`auth/uitokenvalidation`, { token, ui })
				.then((data) => {
					if (!data) return;
					context.commit(SET_AUTH, data);
					context.dispatch('fetchUserReportFilters', data.reportFilters || []);
					context.dispatch(GET_PERMISSION, { service: 'All', viewAs: false }).then(() => {
						resolve(data);
					});
				})
				.catch((response) => {
					context.commit(SET_ERROR, response.responseText);
				});
		});
	},

	[VIEW_AS_LOGIN](context, user) {
		return new Promise((resolve) => {
			ApiService.post(`user/viewaslogin`, { id: user.userId })
				.then((data) => {
					if (!data) return;
					context.commit(SET_VIEW_AS_AUTH, { ...data, viewName: user.name });
					context.dispatch('fetchUserReportFilters', data.reportFilters || []);
					context.dispatch(GET_PERMISSION, { service: 'All', viewAs: true }).then(() => {
						EventBus.$emit('forceRenderBaseComponent');
						resolve(data);
					});
				})
				.catch((response) => {
					context.commit(SET_ERROR, response.responseText);
				});
		});
	},
	[LOGOUT](context) {
		return new Promise((resolve, reject) => {
			if (context.getters.isAuthenticated && ApiService.http) {
				const payload = { id: context.getters.currentUser.userId, token: context.getters.token };

				ApiService.post(`auth/logout`, payload)
					.then(() => {
						Vue.$keycloak.logout();
						context.commit(PURGE_AUTH);
						context.commit(PURGE_VIEW_AS_AUTH);
						resolve();
					})
					.catch((response) => {
						context.commit(SET_ERROR, response.responseText);
						reject();
					});
			} else reject();
		});
	},
	[LOGOUT_VIEW_AS](context) {
		context.dispatch('fetchUserReportFilters', context.state.user.reportFilters || []);
		context.commit(PURGE_VIEW_AS_AUTH);
	},
};

const mutations = {
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	[SET_TOKEN](state, token) {
		state.token = token;
		state.errors = null;
	},
	[SET_AUTH](state, user) {
		state.user = user;
		state.errors = null;
	},
	[SET_VIEW_AS_AUTH](state, viewAsUser) {
		state.viewAsUser = viewAsUser;
		state.viewAsUsertoken = viewAsUser.token;
		state.errors = null;
		state.viewAs = true;
	},
	[SET_PERMISSION](state, permissions) {
		state.permissions = permissions;
		state.errors = null;
	},
	[SET_VIEW_AS_PERMISSION](state, permissions) {
		state.viewAsUserPermissions = permissions;
		state.errors = null;
	},
	[PURGE_AUTH](state) {
		state.isAuthenticated = false;
		state.user = null;
		state.token = null;
		state.permissions = [];
		state.errors = null;
	},
	[PURGE_VIEW_AS_AUTH](state) {
		EventBus.$emit('forceRenderBaseComponent');
		state.viewAsUser = null;
		state.viewAsUsertoken = null;
		state.viewAsUserPermissions = [];
		state.errors = null;
		state.viewAs = false;
	},
	[PERMISSION_CHECKED](state, checked) {
		state.userPemissionChecked = checked;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
