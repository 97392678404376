/* eslint-disable no-unused-vars */
import i18n from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import { ApiService, CKEditor, IS_PROD, STATIC_BUCKET, keyCloackPlg } from '@headerlift/library';
import Vue from 'vue';
import VueGtag from 'vue-gtag';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import GlobalComponents from './common/globalComponents';
import GlobalFilters from './common/globalFilters';
import GlobalFunctions from './common/globalFunctions';

Vue.prototype.$staticBucketUrl = STATIC_BUCKET;
Vue.config.productionTip = false;

Vue.use(GlobalComponents);
Vue.use(GlobalFunctions);
Vue.use(GlobalFilters);
Vue.use(CKEditor);
Vue.use(keyCloackPlg);

if (IS_PROD) {
	Vue.use(
		VueGtag,
		{
			config: { id: process.env.VUE_APP_ANALYTICS_ID },
			appName: 'Headerlift Reporting',
			pageTrackerScreenviewEnabled: true,
		},
		router,
	);
}

Vue.$keycloak
	.init({
		onLoad: 'check-sso',
		checkLoginIframe: false,
	})
	.then((authenticated) => {
		if (!authenticated) store.dispatch('logout');
		new Vue({
			router,
			store,
			vuetify,
			i18n,
			beforeCreate() {
				// API service init
				const apiSettings = { $store: this.$store };
				// eslint-disable-next-line no-new
				new ApiService(apiSettings);
				if (store.getters.isAuthenticated) {
					store.dispatch('getPermission', {
						service: 'All',
						viewAs: false,
					});
				}
			},
			render: (h) => h(App),
		}).$mount('#app');
	});
